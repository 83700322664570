import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/vote"

// 辅助函数：构建请求路径
function buildUrl(path, params) {
	return path.replace(/:[^/]+/g, match => params[match.slice(1)])
}

// 辅助函数：验证和清理数据
function validateAndCleanData(data) {
	// 这里可以添加具体的数据验证逻辑
	return data
}

export default {
	...commonApi(moduleName),
	// 获取列表
	apiGetList: data => {
		const validatedData = validateAndCleanData(data)
		return request("/votes/full-query", {
			params: validatedData
		}).catch(error => {
			console.error("获取列表失败:", error)
			throw error
		})
	},
	apiCancel: data => {
		const validatedData = validateAndCleanData(data)
		return request(
			buildUrl(`${moduleName}/:id/cancel`, { id: validatedData.id }),
			{
				method: "post"
			}
		).catch(error => {
			console.error("取消投票失败:", error)
			throw error
		})
	},
	apiVoting: data => {
		const validatedData = validateAndCleanData(data)
		return request(
			buildUrl(`${moduleName}/:id/voting`, { id: validatedData.id }),
			{
				method: "post",
				body: validatedData
			}
		).catch(error => {
			console.error("投票失败:", error)
			throw error
		})
	},
	apiMobileVote: data => {
		const validatedData = validateAndCleanData(data)
		return request("/s-trial/vote/:voteId/voting-ethic", {
			method: "post",
			body: validatedData
		}).catch(error => {
			console.error("移动投票失败:", error)
			throw error
		})
	},
	apiGetVoteForm: data => {
		const validatedData = validateAndCleanData(data)
		return request("/s-trial/votes/code-query", {
			method: "get",
			params: validatedData
		}).catch(error => {
			console.error("获取投票表单失败:", error)
			throw error
		})
	},
	// 获取项目团队指定角色成员列表
	apiGetRoles: data => {
		const validatedData = validateAndCleanData(data)
		return request("/project/:projectId/team-users/assign-role", {
			method: "get",
			params: validatedData
		}).catch(error => {
			console.error("获取项目团队成员失败:", error)
			throw error
		})
	}
}
