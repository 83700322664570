<template>
	<div class="vote-in-wrap">
		<Spin fix v-if="loading"></Spin>
		<div class="vote-con">
			<van-form @submit="handleSubmit">
				<van-field
					readonly
					label="项目名称"
					name="projectName"
					:value="projectName"
					rows="1"
					autosize
					type="textarea"
					placeholder="项目名称"
				/>
				<van-field
					readonly
					clickable
					label="投票人"
					name="name"
					:value="name"
					placeholder="请选择投票人"
					:rules="[{ required: true, message: '请选择投票人' }]"
					@click="showPickername = true"
				/>
				<van-popup v-model="showPickername" position="bottom">
					<van-picker
						title="伦理委员"
						show-toolbar
						:columns="ethicOptions"
						@confirm="val => onConfirm(val, 'name')"
						@cancel="showPickername = false"
					/>
				</van-popup>
				<van-field
					readonly
					clickable
					name="options"
					label="投票意见"
					:value="options"
					placeholder="请选择投票意见"
					:rules="[{ required: true, message: '请选择投票意见' }]"
					@click="showPickeroptions = true"
				/>
				<van-popup v-model="showPickeroptions" round position="bottom">
					<van-picker
						title="投票意见"
						show-toolbar
						:columns="voteOptions"
						@confirm="val => onConfirm(val, 'options')"
						@cancel="showPickeroptions = false"
					/>
				</van-popup>
				<van-field
					name="isNext"
					v-if="options === '修改后同意/修改后批准'"
					label="是否需再次上会"
				>
					<template #input>
						<van-radio-group v-model="isNext" direction="horizontal">
							<van-radio name="0" checked-color="#2D8cF0">否</van-radio>
							<van-radio name="1" checked-color="#2D8cF0">是</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field
					readonly
					clickable
					name="voteDate"
					label="投票日期"
					:value="voteDate"
					placeholder="请选择投票日期"
					:rules="[{ required: true, message: '请选择投票日期' }]"
					@click="showPickervoteDate = true"
				/>
				<van-calendar
					v-model="showPickervoteDate"
					color="#2D8cF0"
					@confirm="val => onConfirm(val, 'voteDate')"
				/>
				<van-field
					v-model="remark"
					name="remark"
					rows="3"
					autosize
					label="批注"
					type="textarea"
					placeholder="请输入批注"
				>
				</van-field>
				<div style="margin: 16px">
					<van-button
						round
						block
						type="primary"
						:disabled="isVoted"
						native-type="submit"
					>
						提交投票
					</van-button>
				</div>
			</van-form>
		</div>
	</div>
</template>

<script>
import api from "@/api/project/vote"
import moment from "moment"
import { getParameterByName } from "@/utils/util"
import {
	Button,
	Picker,
	Field,
	Popup,
	Form,
	Dialog,
	Toast,
	Calendar,
	RadioGroup,
	Radio
} from "vant"
import "vant/lib/picker/style"
import "vant/lib/field/style"
import "vant/lib/popup/style"
import "vant/lib/button/style"
import "vant/lib/form/style"
import "vant/lib/dialog/style"
import "vant/lib/calendar/style"
import "vant/lib/radio-group/style"
import "vant/lib/radio/style"

const { apiMobileVote, apiGetRoles, apiGetVoteForm } = api

export default {
	name: "Vote",
	components: {
		VanButton: Button,
		VanPicker: Picker,
		VanField: Field,
		VanPopup: Popup,
		VanForm: Form,
		VanCalendar: Calendar,
		VanRadioGroup: RadioGroup,
		VanRadio: Radio
	},
	data() {
		return {
			loading: false,
			isVoted: false,
			ethicList: [],
			ethicOptions: [],
			voteOptions: [],
			voteStatus: "", // 投票进行中=1；已结束=2；已取消=3；
			voteId: "",
			code: "",
			projectId: "",
			projectName: "",
			name: "",
			voteDate: moment().format("YYYY-MM-DD"), // 投票日期默认是当天
			isNext: "0", // 是否再次上会 1 是 ，0 否
			remark: "",
			options: "",
			showPickername: false,
			showPickeroptions: false,
			showPickervoteDate: false
		}
	},
	mounted() {
		this.code = getParameterByName("code")
		this.init()
	},
	methods: {
		async init() {
			this.loading = true
			const res = await apiGetVoteForm({
				code: this.code
			})
			if (res && res.data) {
				this.projectName = res.data.projectName
				this.projectId = res.data.projectId
				this.voteId = res.data.id
				if (res.data.resultInfo) {
					this.voteOptions = Object.keys(res.data.resultInfo)
				}
				this.$nextTick(() => {
					this.getEthics()
				})
			}
			this.loading = false
		},
		// 伦理委员
		async getEthics() {
			const res = await apiGetRoles({
				projectId: this.projectId,
				roleId: "5"
			})
			if (res && res.data && res.data.length) {
				this.ethicList = res.data[0].userList || []
				this.ethicOptions = this.ethicList.map(item => item.name)
			}
		},
		//  关闭 弹层，触发。1,投票人，2，投票意见
		onConfirm(value, key) {
			if (key === "voteDate") {
				this[key] = moment(value).format("YYYY-MM-DD")
			} else {
				this[key] = value
			}
			this[`showPicker${key}`] = false
		},
		handleSubmit(values) {
			Dialog.confirm({
				title: "提示",
				message:
					"是否确认提交投票？若本人已提交过该项目投票，选择确认，投票结果会以最新提交为准。",
				confirmButtonColor: "#2D8cF0"
			})
				.then(async () => {
					this.loading = true
					const res = await apiMobileVote({
						voteId: this.voteId,
						options: this.options,
						name: this.name,
						userId: this.ethicList.find(item => item.name === this.name).id,
						remark: this.remark,
						isNext: Number(this.isNext),
						voteDate: this.voteDate
					})
					if (res) {
						Toast.success("投票成功")
						this.isVoted = true
						this.$router.push({
							name: "mobileVoteSuccess"
						})
					}
					this.loading = false
				})
				.catch(() => {
					// on cancel
				})
		}
	}
}
</script>

<style lang="less">
body {
	min-width: 0;
}
.vote-in-wrap {
	position: relative;
	z-index: 2;
	overflow: auto;
	width: 100vw;
	height: 100vh;
	background: #fefdf8 url("../../../../assets/mobileimages/bg-sign.png")
		no-repeat;
	background-size: 100% 100%;
	.vote-con {
		padding: 20px 25px 80px;
	}
	.van-field__label {
		width: 7em;
	}
	.van-cell__value {
		text-align: left;
	}
	.van-picker__confirm {
		color: #2d8cf0;
	}
	.vote-btn {
		position: fixed;
		bottom: 10px;
		left: 0;
		right: 0;
	}
	.van-button--primary {
		//background: linear-gradient(to right, #fe834b, #fda21e);
		background: #1c2730;
		border-color: #2d8cf0;
	}
	.van-field--error .van-field__control::placeholder {
		color: #c5c8ce;
	}
}
</style>
